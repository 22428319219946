.footer-wrapper {
  ul {
    padding-left: 0;

    a {
      color: $white;
      font-size: 14px;

      &:hover {
        color: $white;
      }
    }
  }
}

.region-footer-top {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  justify-items: center;
  padding: 30px 0;
}

.block-copyright {
  margin-bottom: 0;
  padding-bottom: 30px;

  p {
    margin: 0;
  }
}

.block-map {
  width: 40%;
  margin: 0;
}
