// COLORS
// Define all color variables in this document
// Use http://chir.ag/projects/name-that-color/ for naming a color.

$black: #000;
$gray: #808080;
$white: #fff;
$silver: #ccc;
$pigeon-post: #aec9de;
$pigeon-post-light: rgba($pigeon-post, .75);

$gray-11: #111;
$gray-44: #444;

$yellow: #f6cb64;
$pink: #B33E3C;


$primary-color: $pink;
$secondary-color: $yellow;
$text-color: $white;

$link-color: $primary-color;
$link-color-hover: $secondary-color;

// Button color
$btn-color: $primary-color;
$btn-color-hover: rgba($primary-color, .75);
