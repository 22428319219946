%icons {
  font-family: 'vertu';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*=' icon-'] {
  &:before {
    @extend %icons;
  }
}

.icon-menu:before {
  content: "\e91c";
}
.icon-shopping_basket:before {
  content: "\e91b";
}
.icon-chevrons-right:before {
  content: "\e91a";
}
.icon-chevrons-left:before {
  content: "\e919";
}
.icon-home:before {
  content: "\e918";
}
.icon-phone:before {
  content: "\e916";
}
.icon-envelope-o:before {
  content: "\e915";
}
.icon-close3:before {
  content: "\e917";
}
.icon-search2:before {
  content: "\e914";
}
.icon-cart:before {
  content: "\e913";
}
.icon-chevron-thin-down:before {
  content: "\e90f";
}
.icon-google:before {
  content: "\e911";
}
.icon-linkedin-square:before {
  content: "\e910";
}
.icon-star:before {
  content: "\e90e";
}
.icon-minus:before {
  content: "\e90c";
}
.icon-chevron-left:before {
  content: "\e90d";
}
.icon-chevron-down:before {
  content: "\e900";
}
.icon-plus:before {
  content: "\e901";
}
.icon-triangle-down:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-play:before {
  content: "\e905";
}
.icon-instagram:before {
  content: "\e906";
}
.icon-twitter:before {
  content: "\e907";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-facebook-f:before {
  content: "\e908";
}
.icon-magnifying-glass:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e909";
}
.icon-glass:before {
  content: "\e909";
}
.icon-magnifier:before {
  content: "\e909";
}
.icon-lookup:before {
  content: "\e909";
}
.icon-chevron-thin-up:before {
  content: "\e912";
}
.icon-chevron-thin-left:before {
  content: "\e90b";
}
.icon-chevron-thin-right:before {
  content: "\e90a";
}
.icon-cheveron-right:before {
  content: "\e904";
}
