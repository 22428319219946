// Pager

.pager {
  @extend %clearfix;
  font-size: rem(14px);
  padding-left: rem(1px);
  margin: rem(30px 0);

  li {
    border: rem(1px) solid $gray-11;
    float: left;
    margin: rem(0 10px 5px 0);
    font-size: rem(16px);

    &:last-of-type {
      margin-right: 0;
    }
  }

  .pager-ellipsis,
  .pager-current,
  a {
    display: block;
    padding: rem(0 10px);
  }

  .pager-ellipsis {
    border-color: transparent;
  }

  a {
    color: $text-color;
  }

  &-first,
  &-previous,
  &-next,
  &-last {
    a {
      position: relative;
      text-indent: -999em;
      overflow: hidden;
      width: rem(30px);

      &:before {
        @extend %icons;
        font-size: rem(22px);
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        text-indent: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &-previous {
    a {
      @extend .icon-chevron-left;
    }
  }

  &-next {
    a {
      @extend .icon-cheveron-right;
    }
  }

  &-first {
    a {
      @extend .icon-chevrons-left;
    }
  }

  &-last {
    a {
      @extend .icon-chevrons-right;
    }
  }

  .pager-current {
    background: $primary-color;
    border-color: $primary-color;
    color: $white;
    cursor: default;
    position: relative;
    z-index: 1;
    width: 47px;
  }
}
