%btn,
.btn {
  appearance: none;
  background-image: none;
  border-radius: 0;
  border: rem(1px) solid $btn-color;
  box-shadow: none;
  display: inline-block;
  font-size: rem(18px);
  line-height: 1;
  outline: none;
  padding: rem(0 40px);
  height: 45px;
  line-height: 40px;
  text-align: center;
  text-shadow: none;
  border-radius: rem(3px);
  transition: all .3s ease;
  background-color: $btn-color;
  color: $white;
  cursor: pointer;
  outline: 0;

  &:active,
  &:hover {
    background-color: $btn-color-hover;
    border-color: $btn-color-hover;
    color: $white;
  }
}
