// Each block in the templates/blocks folder should have a
// corresponding .scss file

.blog-grid {
  &__post {
    @include gallery(2 of 4);
    margin-bottom: rem(20px);

    @include susy-media(tablet) {
      @include gallery(5 of 10);
      margin-bottom: gutter(10);
    }

    @include susy-media(desktop) {
      @include gallery(4 of 12);
      margin-bottom: gutter(12);
    }

    &__title {
      font-size: rem(40px);
      text-align: center;
    }
  }
}
