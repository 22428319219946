.block-social-media-links {
  width: 60%;
  margin: 0;

  .block-title {
    border: 0;
    padding: 0;
    font-size: 16px;
    font-weight: normal;

    &:before {
      border: 0;
    }
  }
}
