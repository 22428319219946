#search-block-form {
  .container-inline {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;

    @include susy-media(mobileOnly) {
      justify-content: flex-start;
    }

    .form-type-textfield  {
      margin: 0;
      width: 200px;

      @include susy-media(desktopWide) {
        width: 260px;
      }
    }
  }
}

.block-search {
  margin-bottom: 0;
  position: relative;

  .form-actions,
  .search-icon {
    @extend %icons;
    @extend .icon-search2;
    font-size: 20px;
    cursor: pointer;
  }

  .search-icon--active {
    color: $primary-color;
  }

  .form-autocomplete {
    background-image: none !important;
  }

  form {
    .form-text {
      padding-right: 40px;
      border-top: 0;
      height: 30px;
      border-radius: 15px;
      background: transparent;
      border: 1px solid $gray;
    }

    .form-actions {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
      height: 30px;
      width: 40px;
      height: 100%;
      text-align: center;
      line-height: 30px;

      &:before {
        color: rgba($white, 0.5);
      }
    }

    .form-submit {
      opacity: 0;
      position: absolute;
      right: 0;
      height: 30px;
      width: 30px;
    }
  }

  &.search-box--show {
    form {
      display: block;
    }

    .search-icon {
      font-size: 30px;
      @extend .icon-close3;
    }
  }
}
