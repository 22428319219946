// VARIABLES
// Define all variables (aside from colors) in this document. This includes breakpoint, sizes, etc.

//Breakpoints
$mobile-landscape: 480px;
$mobile-landscape1: 568px;
$tablet: 768px;
$desktop: 1024px;
$desktop-wide: 1280px;
$desktop-superwide: 1440px;
$desktop-superwide-large: 1500px;

// Grid variables
$container-margin: 10px;
$container-margin-tablet: 20px;
$container-margin-desktop: 30px;
$container-desktop-wide: 1200px;

$column-mobile: 4;
$column-tablet: 8;
$column-desktop: 12;

//Font variables
$base-font-size: 16px;
$base-line-height: 30px;

// Add global paths
$image-path: '../images/';
