.block-commerce-popup-cart {
  margin: 0;
  float: none;

  h2 {
    display: none;
  }

  .cart-icon {
    background: none;
    @extend %icons;
    @extend .icon-shopping_basket;
    font-size: 23px;
    color: rgba($white, 0.5);
    height: 100%;
    width: 24px;
    cursor: pointer;
    margin-right: 12px;
    float: right;
  }

  .cart_popup_count {
    color: $primary-color;
    position: absolute;
    top: 0;
    margin: 0;
    right: 0;
  }
}
