.view-new  {
  .views-row {
    .node-new {
      align-items: stretch;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      padding-bottom: rem(30px);
      border-bottom: 1px solid $silver;
      margin-bottom: 30px;
    }

    &:last-of-type {
      .node-new {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    .field-type-image {
      padding-right: 30px;
      width: 25%;
    }

    .group-content {
      width: 75%;
    }
  }
}

.view-category {
  .view-content {
    > .item-list > ul > li {
      margin-left: 0;
    }

    a {
      color: $text-color;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
