// INDEX
// Please define all styles for the index page in this file.
// For each new page template added to /templates/pages/*.twig,
// please add a corresponding file in /assets/scss/pages/*.scss.

.welcome-paragraph {
  padding: rem(40px 0);
}

.breadcrumb {
  padding: 40px 0 30px;

  a {
    color: $text-color;

    &:hover {
      color: $primary-color;
    }
  }
}
