.view-category-product {
  > .view-content {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: rem(50px);

    > .views-row {
      @extend .bg--dark--overlay;
      margin-right: rem(1px);
      margin-bottom: 1px;
      width: calc((100% - #{2 * rem(1px)}) / 3);
      position: relative;
      height: 250px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .views-field-field-cta-image {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
          transition: transform .3s ease;
          line-height: 0;
          position: absolute;
          transform: translateY(-50%) scale(1);
          top: 50%;
          height: 100%;
          width: auto;
          min-height: 100%;
          max-height: 100%;
        }
      }

      .views-field-name {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
        top: 0;

        a {
          color: $white;
          font-size: 24px;
          transform: scale(0);

          &:before {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: "";
            top: 0;
          }
        }
      }

      &:hover {
        .views-field-field-cta-image {
          img {
            transform: translateY(-50%) scale(1.2);
          }
        }
      }
    }
  }
}
