// Prototype navigation

.index-links {
  bottom: rem(20px);
  color: $black;
  display: block;
  font-size: rem(16px);
  left: rem(20px);
  position: fixed;
  z-index: 2000;

  label {
    background-color: $pigeon-post-light;
    border-radius: 20px;
    box-shadow: 0 1px 10px $gray;
    cursor: pointer;
    padding: rem(5px 10px);
    transition: border-radius .4s;
    user-select: none;
  }

  nav {
    background-color: $pigeon-post-light;
    border-radius: 20px 20px 20px 0;
    bottom: rem(26px);
    max-height: 0;
    min-height: 0;
    overflow: hidden;
    padding: rem(0 10px);
    position: absolute;
    transition: max-height .15s ease-out;
  }

  a {
    color: $black;
    display: block;
    padding: rem(5px 0);
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  #index-link {
    display: none;

    &:checked {
      ~ nav {
        max-height: rem(200px);
        transition: max-height .5s ease-in;
      }

      ~ label {
        border-radius: 0 0 20px 20px;
        border-top: 1px solid $black;
        transition: border-radius .1s;
      }
    }
  }
}
