.front {
  .header {
    position: absolute;
  }
}

.logged-in {
  .header {
    top: 53.8px;

    @media only screen and (min-width: 888px) {
      top: 29px;
    }
  }
}

.header {
  color: $white;
  transition: all 0.3s ease;
  left: 0;
  width: 100%;
  z-index: 999;
  top: 0;
  background: rgba(0,0,0,0.6);

  > .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    justify-items: stretch;
    position: relative;
    padding: 5px 0;

    @include susy-media(mobileOnly) {
      padding-bottom: 50px;
    }
  }

  &__logo {
    width: 100%;

    @include susy-media(tablet) {
      width: 20%;
    }

    @include susy-media(desktop) {
      width: 15%;
      margin-right: 30px;
    }

    .logo {
      display: flex;
      justify-content: flex-start;
      align-content: center;

      @include susy-media(mobileOnly) {
        align-content: center;
        margin: auto;
        width: 100%;
        text-align: center;
        display: inline-block;
      }

      img {
        width: 100px;
        height: 100px;

        @include susy-media(mobileOnly) {
          margin: 0 auto;
        }

        @include susy-media(desktop) {
          width: 125px;
          height: 125px;
        }
      }
    }
  }

  &.fixed-header {
    top: 0;
    box-shadow: 0rem 0rem 0.5rem 0rem rgba(255, 255, 255, 0.1);
  }

  .region-header {
    position: absolute;
    top: 0;
    left: 16%;
    padding-top: 8px;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    @include susy-media(mobileOnly) {
      bottom: 0;
      left: 0;
      top: inherit;
      width: 100%;
      padding-bottom: 10px;
    }

    .block-support {
      width: 100%;

      @include susy-media(tablet) {
        width: 60%;
      }
    }

    .block-search {
      @include susy-media(mobileOnly) {
        display: none;
      }

      @include susy-media(tablet) {
        width: 40%;
        margin-right: 15px;
      }
    }

    .block-search + .block-commerce-popup-cart{
      width: 5%;
    }
  }
}
