.block-service {
  padding-top: 40px;

  .content {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: rem(50px);
  }

  &__item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    text-align: center;
    padding: 0 25px;
    justify-items: center;
    margin-bottom: 30px;

    @include susy-media(tablet) {
      margin-right: rem(25px);
      margin-bottom: 0;
      width: calc((100% - #{2 * rem(25px)}) / 3);

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    h4 {
      font-size: 20px;
      font-weight: bold;
    }
  }

  &__image {
    margin-bottom: 15px;
  }
}
