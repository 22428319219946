.block-support {
  margin: 0;

  .container {
    margin: 0;
  }

  ul {
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    padding: 0;

    @include susy-media(tablet) {
      flex-flow: row nowrap;
    }

    li {
      margin-right: 15px;
      color: $primary-color;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include susy-media(desktop) {
        font-size: 14px;
      }

      &:before {
        padding-right: 8px;
        color: rgba($white, 0.5);
        font-size: 20px;
      }

      a {
        color: $primary-color;;

        &:hover {
          text-decoration: underline;
        }
      }

      &.icon-envelope-o {
        display: none;

        @include susy-media(desktop) {
          display: flex;
        }
      }
    }
  }
}
