// Texts
.text {
  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  // Dark Colors
  &--dark {
    color: $text-color;
  }

  // Light Colors
  &--white {
    color: $white;

    a {
      @extend %link--white;
    }
  }

  &--primary {
    color: $primary-color;
  }
}

// Backgrounds
.bg {
  // Dark backgrounds
  &--primary {
    background-color: $primary-color;

    &--overlay {
      @include gradient-overlay($primary-color, .6);
    }
  }

  &--dark {
    background-color: $gray-11;

    &--overlay {
      @include gradient-overlay($gray-11, .6);
    }
  }

  // Light backgrounds
  &--white {
    background-color: $white;
  }
}

.block {
  margin-bottom: rem(30px);
}

.block-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  display: inline-block;

  &:before {
    border-bottom: 3px solid $primary-color;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70px;
  }
}
