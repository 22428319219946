.block-quote-4:before, .pager:before, .block-quote-4:after, .pager:after {
  content: '';
  display: table; }

.block-quote-4:after, .pager:after {
  clear: both; }

[class^='icon-']:before,
[class*=' icon-']:before, .touchevents .form-type-select .select-arrow-icon:before, .slick-arrow:before, .block-commerce-popup-cart .cart-icon, .pager-first a:before, .pager-previous a:before, .pager-next a:before, .pager-last a:before, .block-search .form-actions,
.block-search .search-icon, .breadcrumb a:first-of-type:before, .main-menu__icon {
  font-family: 'vertu';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-menu:before, .main-menu__icon:before {
  content: "\e91c"; }

.icon-shopping_basket:before, .block-commerce-popup-cart .cart-icon:before {
  content: "\e91b"; }

.icon-chevrons-right:before, .pager-last a:before {
  content: "\e91a"; }

.icon-chevrons-left:before, .pager-first a:before {
  content: "\e919"; }

.icon-home:before, .breadcrumb a:first-of-type:before {
  content: "\e918"; }

.icon-phone:before {
  content: "\e916"; }

.icon-envelope-o:before {
  content: "\e915"; }

.icon-close3:before, .block-search.search-box--show .search-icon:before, .main-menu.show .main-menu__icon:before {
  content: "\e917"; }

.icon-search2:before, .block-search .form-actions:before,
.block-search .search-icon:before {
  content: "\e914"; }

.icon-cart:before {
  content: "\e913"; }

.icon-chevron-thin-down:before, .touchevents .form-type-select .select-arrow-icon:before {
  content: "\e90f"; }

.icon-google:before {
  content: "\e911"; }

.icon-linkedin-square:before {
  content: "\e910"; }

.icon-star:before {
  content: "\e90e"; }

.icon-minus:before {
  content: "\e90c"; }

.icon-chevron-left:before, .pager-previous a:before {
  content: "\e90d"; }

.icon-chevron-down:before {
  content: "\e900"; }

.icon-plus:before {
  content: "\e901"; }

.icon-triangle-down:before {
  content: "\e902"; }

.icon-arrow-down:before {
  content: "\e903"; }

.icon-play:before {
  content: "\e905"; }

.icon-instagram:before {
  content: "\e906"; }

.icon-twitter:before {
  content: "\e907"; }

.icon-facebook:before {
  content: "\e908"; }

.icon-facebook-f:before {
  content: "\e908"; }

.icon-magnifying-glass:before {
  content: "\e909"; }

.icon-search:before {
  content: "\e909"; }

.icon-glass:before {
  content: "\e909"; }

.icon-magnifier:before {
  content: "\e909"; }

.icon-lookup:before {
  content: "\e909"; }

.icon-chevron-thin-up:before {
  content: "\e912"; }

.icon-chevron-thin-left:before {
  content: "\e90b"; }

.icon-chevron-thin-right:before {
  content: "\e90a"; }

.icon-cheveron-right:before, .slick-arrow:before, .pager-next a:before {
  content: "\e904"; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  outline: 0 none;
  padding: 0;
  vertical-align: baseline; }

audio,
canvas,
video {
  display: inline-block; }

ol,
ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption,
th,
td {
  font-weight: normal;
  text-align: left;
  vertical-align: middle; }

q,
blockquote {
  quotes: none; }

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: none; }

a img {
  border: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block; }

img {
  font-size: 0;
  height: auto; }

img,
object,
embed {
  max-width: 100%; }

@font-face {
  font-family: "vertu";
  src: url("../fonts/vertu.eot?") format("eot"), url("../fonts/vertu.woff2") format("woff2"), url("../fonts/vertu.woff") format("woff"), url("../fonts/vertu.ttf") format("truetype"), url('../fonts/vertu.svg#str-replace(vertu, " ", "_")') format("svg"); }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/OpenSans-Bold.eot?") format("eot"), url("../fonts/OpenSans-Bold.woff2") format("woff2"), url("../fonts/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans-Bold.ttf") format("truetype"), url('../fonts/OpenSans-Bold.svg#str-replace(OpenSans, " ", "_")') format("svg"); }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/OpenSans-Regular.eot?") format("eot"), url("../fonts/OpenSans-Regular.woff2") format("woff2"), url("../fonts/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans-Regular.ttf") format("truetype"), url('../fonts/OpenSans-Regular.svg#str-replace(OpenSans, " ", "_")') format("svg"); }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/OpenSans-Light.eot?") format("eot"), url("../fonts/OpenSans-Light.woff2") format("woff2"), url("../fonts/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light.ttf") format("truetype"), url('../fonts/OpenSans-Light.svg#str-replace(OpenSans, " ", "_")') format("svg"); }


.container {
  max-width: 100%;
  margin-left: 0.625rem;
  margin-right: 0.625rem; }
  
  .container:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 48em) {
    
    .container {
      max-width: 100%;
      margin-left: 1.25rem;
      margin-right: 1.25rem; }
      
      .container:after {
        content: " ";
        display: block;
        clear: both; } }
  @media (min-width: 64em) {
    
    .container {
      max-width: 100%;
      margin-left: 1.875rem;
      margin-right: 1.875rem; }
      
      .container:after {
        content: " ";
        display: block;
        clear: both; } }
  @media (min-width: 80em) {
    
    .container {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto; }
      
      .container:after {
        content: " ";
        display: block;
        clear: both; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.9375rem;
  font-weight: 400; }

h1 {
  font-size: 2.25rem;
  line-height: 36px; }
  @media (min-width: 48em) {
    h1 {
      line-height: 45px;
      font-size: 2.8125rem; } }

h2 {
  font-size: 2.625rem;
  line-height: 42px; }

h3 {
  font-size: 2.25rem;
  line-height: 36px; }

h4 {
  font-size: 1.75rem; }

h5 {
  font-size: 1.125rem; }

h6 {
  font-size: 1rem; }

a,
.link {
  color: #B33E3C;
  text-decoration: none;
  transition: all .3s ease;
  cursor: pointer; }
  a:active, a:hover,
  .link:active,
  .link:hover {
    color: #f6cb64; }
  .text--white a, .slick-white-control .slick-arrow,
  .link--white {
    color: #fff; }
    .text--white a:active, .slick-white-control .slick-arrow:active, .text--white a:hover, .slick-white-control .slick-arrow:hover,
    .link--white:active,
    .link--white:hover {
      color: #d9d9d9; }

p {
  margin-bottom: 0.9375rem; }

em {
  font-style: italic; }

b,
strong {
  font-weight: 700; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super; }

small {
  font-size: smaller; }

abbr {
  border-bottom: 1px dotted #fff;
  cursor: help; }

tt,
code,
kbd,
samp {
  font-family: monospace; }

ol,
ul {
  padding-left: 1.125rem;
  margin-bottom: 0.9375rem; }

label {
  font-weight: 700; }

blockquote p {
  display: inline; }

blockquote:before {
  content: '\201c'; }

blockquote:after {
  content: '\201d'; }

iframe {
  max-width: 100%; }

figure {
  line-height: 0; }

#page-title {
  margin-bottom: 1.875rem; }


.btn,
button,
input[type='submit'],
.slick--view--slide .slide__link a {
  appearance: none;
  background-image: none;
  border-radius: 0;
  border: 0.0625rem solid #B33E3C;
  box-shadow: none;
  display: inline-block;
  font-size: 1.125rem;
  line-height: 1;
  outline: none;
  padding: 0rem 2.5rem;
  height: 45px;
  line-height: 40px;
  text-align: center;
  text-shadow: none;
  border-radius: 0.1875rem;
  transition: all .3s ease;
  background-color: #B33E3C;
  color: #fff;
  cursor: pointer;
  outline: 0; }
  
  .btn:active,
  button:active,
  input[type='submit']:active,
  .slick--view--slide .slide__link a:active,
  .btn:hover,
  button:hover,
  input[type='submit']:hover,
  .slick--view--slide .slide__link a:hover {
    background-color: rgba(179, 62, 60, 0.75);
    border-color: rgba(179, 62, 60, 0.75);
    color: #fff; }

.grippie {
  display: none; }

input[type='tel'],
.form-url,
.form-search,
.form-email,
.form-text, .form-textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: 0.0625rem solid #808080;
  box-shadow: inherit;
  box-sizing: border-box;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  height: 2.5rem;
  padding: 0.4375rem 1.25rem;
  transition: all .3s ease-in-out;
  width: 100%;
  border-radius: 5px; }
  input[type='tel']::-ms-clear,
  .form-url::-ms-clear,
  .form-search::-ms-clear,
  .form-email::-ms-clear,
  .form-text::-ms-clear, .form-textarea::-ms-clear {
    display: none; }
  input[type='tel']::-webkit-input-placeholder,
  .form-url::-webkit-input-placeholder,
  .form-search::-webkit-input-placeholder,
  .form-email::-webkit-input-placeholder,
  .form-text::-webkit-input-placeholder, .form-textarea::-webkit-input-placeholder {
    color: #808080;
    opacity: 1; }
  input[type='tel']::-moz-placeholder,
  .form-url::-moz-placeholder,
  .form-search::-moz-placeholder,
  .form-email::-moz-placeholder,
  .form-text::-moz-placeholder, .form-textarea::-moz-placeholder {
    color: #808080;
    opacity: 1; }
  input[type='tel']:-ms-input-placeholder,
  .form-url:-ms-input-placeholder,
  .form-search:-ms-input-placeholder,
  .form-email:-ms-input-placeholder,
  .form-text:-ms-input-placeholder, .form-textarea:-ms-input-placeholder {
    color: #808080;
    opacity: 1; }
  input[type='tel']:-moz-placeholder,
  .form-url:-moz-placeholder,
  .form-search:-moz-placeholder,
  .form-email:-moz-placeholder,
  .form-text:-moz-placeholder, .form-textarea:-moz-placeholder {
    color: #808080;
    opacity: 1; }
  input[type='tel']:focus,
  .form-url:focus,
  .form-search:focus,
  .form-email:focus,
  .form-text:focus, .form-textarea:focus {
    outline: 0 none; }
  input.error[type='tel'],
  .error.form-url,
  .error.form-search,
  .error.form-email,
  .error.form-text, .error.form-textarea {
    border-color: red;
    color: red; }
    input.error[type='tel']::-webkit-input-placeholder,
    .error.form-url::-webkit-input-placeholder,
    .error.form-search::-webkit-input-placeholder,
    .error.form-email::-webkit-input-placeholder,
    .error.form-text::-webkit-input-placeholder, .error.form-textarea::-webkit-input-placeholder {
      color: red; }
    input.error[type='tel']::-moz-placeholder,
    .error.form-url::-moz-placeholder,
    .error.form-search::-moz-placeholder,
    .error.form-email::-moz-placeholder,
    .error.form-text::-moz-placeholder, .error.form-textarea::-moz-placeholder {
      color: red; }
    input.error[type='tel']:-ms-input-placeholder,
    .error.form-url:-ms-input-placeholder,
    .error.form-search:-ms-input-placeholder,
    .error.form-email:-ms-input-placeholder,
    .error.form-text:-ms-input-placeholder, .error.form-textarea:-ms-input-placeholder {
      color: red; }
    input.error[type='tel']:-moz-placeholder,
    .error.form-url:-moz-placeholder,
    .error.form-search:-moz-placeholder,
    .error.form-email:-moz-placeholder,
    .error.form-text:-moz-placeholder, .error.form-textarea:-moz-placeholder {
      color: red; }
    input.error[type='tel']:focus,
    .error.form-url:focus,
    .error.form-search:focus,
    .error.form-email:focus,
    .error.form-text:focus, .error.form-textarea:focus {
      outline: 0 none; }
  input[disabled][type='tel'],
  [disabled].form-url,
  [disabled].form-search,
  [disabled].form-email,
  [disabled].form-text, [disabled].form-textarea {
    cursor: not-allowed; }

.form-textarea {
  height: auto;
  min-height: 12.5rem;
  padding: 0.9375rem 1.5625rem; }

.form-item,
.form-actions {
  margin: 0rem 0rem 0.9375rem; }
  .form-item.form-type-checkbox, .form-item.form-type-radio,
  .form-actions.form-type-checkbox,
  .form-actions.form-type-radio {
    margin-bottom: 0.5rem; }
  .form-item label,
  .form-actions label {
    color: #fff;
    display: inline-block;
    font-weight: 700;
    margin-bottom: 0.3125rem; }

.form-type-checkbox label,
.form-type-radio label {
  color: #fff; }

.fieldset-legend {
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.5rem; }

input:focus + label {
  color: #B33E3C;
  transition: all 0.7s ease; }

.select-arrow-icon {
  display: none; }

.touchevents .form-type-select .select-arrow-icon {
  color: #fff;
  font-size: 1rem;
  height: 0.9375rem;
  line-height: 0.9375rem;
  margin-top: 1.25rem;
  position: absolute;
  right: 0.9375rem;
  text-align: center;
  text-align: left;
  text-indent: -999em;
  width: 0.9375rem;
  z-index: 3;
  display: block; }
  .touchevents .form-type-select .select-arrow-icon:before {
    display: block;
    float: left;
    height: 100%;
    text-align: center;
    text-indent: 0;
    transform: rotate(0);
    width: 100%; }

@media screen and (max-width: 47.9375em) {
  .touchevents .form-type-select {
    position: relative; }
    .touchevents .form-type-select select {
      appearance: none;
      border: 0.0625rem solid #808080;
      background: #fff;
      font-size: 0.875rem;
      height: 3.5rem;
      padding: 0.375rem 2.5rem 0.375rem 0.9375rem;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0; }
      .touchevents .form-type-select select.focused, .touchevents .form-type-select select:focus {
        border-color: #B33E3C;
        outline: none; }
    .touchevents .form-type-select .select-arrow-icon {
      display: block; } }

.touchevents .form-type-select select {
  position: relative;
  z-index: 2; }

.ds-2col-stacked .group-right ul li {
  list-style: inside;
  padding-left: 15px; }

.ds-2col-stacked .group-footer {
  padding-top: 30px;
  margin-bottom: 50px; }
  .ds-2col-stacked .group-footer .group-content {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px; }
  .ds-2col-stacked .group-footer .fieldset-wrapper ul li {
    list-style: inside; }
  .ds-2col-stacked .group-footer div.horizontal-tabs {
    border: none; }
    .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list {
      background: transparent;
      border-right: none;
      margin-bottom: 15px; }
      .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list .horizontal-tab-button {
        background: transparent;
        border: none;
        padding: 0;
        margin-right: 10px; }
        .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list .horizontal-tab-button a {
          padding: 5px 15px; }
          .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list .horizontal-tab-button a:hover {
            background-color: transparent; }
        .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list .horizontal-tab-button a strong {
          color: #fff; }
          .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list .horizontal-tab-button a strong:hover {
            color: #fff;
            background-color: transparent; }
        .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list .horizontal-tab-button:hover, .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list .horizontal-tab-button.selected {
          background: #B33E3C; }
          .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list .horizontal-tab-button:hover a, .ds-2col-stacked .group-footer div.horizontal-tabs .horizontal-tabs-list .horizontal-tab-button.selected a {
            color: #fff; }

.comment-form .form-item, .comment-form .form-actions {
  display: flex;
  justify-content: flex-start; }
  .comment-form .form-item label, .comment-form .form-actions label {
    max-width: 120px;
    width: 100%;
    margin-right: 10px; }

.comment-form .form-textarea-wrapper,
.comment-form .form-textarea,
.comment-form .form-text {
  max-width: 300px; }
  @media (min-width: 48em) {
    .comment-form .form-textarea-wrapper,
    .comment-form .form-textarea,
    .comment-form .form-text {
      max-width: 450px; } }

.text--center {
  text-align: center; }

.text--left {
  text-align: left; }

.text--right {
  text-align: right; }

.text--dark {
  color: #fff; }

.text--white {
  color: #fff; }

.text--primary {
  color: #B33E3C; }

.bg--primary {
  background-color: #B33E3C; }
  .bg--primary--overlay {
    position: relative; }
    .bg--primary--overlay:before {
      background: rgba(179, 62, 60, 0.6);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }

.bg--dark {
  background-color: #111; }
  .bg--dark--overlay, .slick--view--slide .slide__content, .view-category-product > .view-content > .views-row {
    position: relative; }
    .bg--dark--overlay:before, .slick--view--slide .slide__content:before, .view-category-product > .view-content > .views-row:before {
      background: rgba(17, 17, 17, 0.6);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }

.bg--white {
  background-color: #fff; }

.block {
  margin-bottom: 1.875rem; }

.block-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  display: inline-block; }
  .block-title:before {
    border-bottom: 3px solid #B33E3C;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-arrow {
  color: #fff;
  height: 3.125rem;
  overflow: hidden;
  position: absolute;
  text-align: left;
  text-indent: -999em;
  top: 50%;
  transform: translateY(-50%);
  width: 3.125rem;
  z-index: 3;
  background: transparent;
  border: 0;
  padding: 0; }
  .slick-arrow:before {
    font-size: 3.125rem;
    line-height: 3.125rem;
    position: absolute;
    text-indent: 0;
    top: 0; }
  .slick-arrow:hover {
    color: #B33E3C;
    background: transparent;
    border: 0; }

.slick-prev {
  left: 0; }
  .slick-prev:before {
    left: 0;
    transform: rotate(-180deg); }

.slick-next {
  right: 0; }
  .slick-next:before {
    right: 0; }

.slick-dots {
  cursor: default; }
  .slick-dots li {
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    margin: 0rem 0.4375rem 0.3125rem;
    transform: scale(1);
    transition: all .2s ease; }
    .slick-dots li span {
      background-color: #111;
      border-radius: 100%;
      box-sizing: border-box;
      display: block;
      height: 0.5rem;
      margin: 0;
      width: 0.5rem; }
    .slick-dots li:hover, .slick-dots li.slick-active {
      transform: scale(1.4); }
      .slick-dots li:hover span, .slick-dots li.slick-active span {
        background-color: #B33E3C; }
    .slick-dots li:last-of-type {
      margin-right: 0; }
    .slick-dots li:first-of-type {
      margin-left: 0; }
  .slick-dots.disabled li.slick-active {
    transform: scale(1); }
    .slick-dots.disabled li.slick-active span {
      background-color: #111; }

.slick-white-control .slick-dots li span {
  background-color: transparent;
  border: 0.0625rem solid #fff;
  height: 0.375rem;
  width: 0.375rem; }

.slick-white-control .slick-dots li:hover, .slick-white-control .slick-dots li.slick-active {
  transform: scale(1); }
  .slick-white-control .slick-dots li:hover span, .slick-white-control .slick-dots li.slick-active span {
    background-color: #fff; }

.slick-dark-control .slick-dots li span {
  background-color: transparent;
  border: 0.0625rem solid #111;
  height: 0.375rem;
  width: 0.375rem; }

.slick-dark-control .slick-dots li:hover, .slick-dark-control .slick-dots li.slick-active {
  transform: scale(1); }
  .slick-dark-control .slick-dots li:hover span, .slick-dark-control .slick-dots li.slick-active span {
    background-color: #111; }

.blog-grid__post {
  width: 47.89916%;
  float: left;
  margin-bottom: 1.25rem; }
  .blog-grid__post:nth-child(2n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0; }
  .blog-grid__post:nth-child(2n + 2) {
    margin-left: 52.10084%;
    margin-right: -100%;
    clear: none; }
  @media (min-width: 48em) {
    .blog-grid__post {
      width: 49.18033%;
      float: left;
      margin-bottom: 1.63934%; }
      .blog-grid__post:nth-child(2n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      .blog-grid__post:nth-child(2n + 2) {
        margin-left: 50.81967%;
        margin-right: -100%;
        clear: none; } }
  @media (min-width: 64em) {
    .blog-grid__post {
      width: 32.42507%;
      float: left;
      margin-bottom: 1.3624%; }
      .blog-grid__post:nth-child(3n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      .blog-grid__post:nth-child(3n + 2) {
        margin-left: 33.78747%;
        margin-right: -100%;
        clear: none; }
      .blog-grid__post:nth-child(3n + 3) {
        margin-left: 67.57493%;
        margin-right: -100%;
        clear: none; } }
  .blog-grid__post__title {
    font-size: 2.5rem;
    text-align: center; }

.index-links {
  bottom: 1.25rem;
  color: #000;
  display: block;
  font-size: 1rem;
  left: 1.25rem;
  position: fixed;
  z-index: 2000; }
  .index-links label {
    background-color: rgba(174, 201, 222, 0.75);
    border-radius: 20px;
    box-shadow: 0 1px 10px #808080;
    cursor: pointer;
    padding: 0.3125rem 0.625rem;
    transition: border-radius .4s;
    user-select: none; }
  .index-links nav {
    background-color: rgba(174, 201, 222, 0.75);
    border-radius: 20px 20px 20px 0;
    bottom: 1.625rem;
    max-height: 0;
    min-height: 0;
    overflow: hidden;
    padding: 0rem 0.625rem;
    position: absolute;
    transition: max-height .15s ease-out; }
  .index-links a {
    color: #000;
    display: block;
    padding: 0.3125rem 0rem;
    white-space: nowrap; }
    .index-links a:hover {
      text-decoration: underline; }
  .index-links #index-link {
    display: none; }
    .index-links #index-link:checked ~ nav {
      max-height: 12.5rem;
      transition: max-height .5s ease-in; }
    .index-links #index-link:checked ~ label {
      border-radius: 0 0 20px 20px;
      border-top: 1px solid #000;
      transition: border-radius .1s; }

.block-commerce-popup-cart {
  margin: 0;
  float: none; }
  .block-commerce-popup-cart h2 {
    display: none; }
  .block-commerce-popup-cart .cart-icon {
    background: none;
    font-size: 23px;
    color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 24px;
    cursor: pointer;
    margin-right: 12px;
    float: right; }
  .block-commerce-popup-cart .cart_popup_count {
    color: #B33E3C;
    position: absolute;
    top: 0;
    margin: 0;
    right: 0; }

.slick--view--slide {
  position: relative; }
  .slick--view--slide .slide__content {
    height: 600px;
    overflow: hidden; }
    .slick--view--slide .slide__content img {
      bottom: auto;
      height: 100%;
      left: 50%;
      max-width: inherit;
      position: absolute;
      right: auto;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      object-position: 100% 50%;
      object-fit: cover; }
      @media mobileLarge {
        .slick--view--slide .slide__content img {
          width: auto; } }
  .slick--view--slide .slide__group-content {
    align-content: stretch;
    align-items: center;
    bottom: 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    color: #fff; }
  .slick--view--slide .slide__title {
    margin-bottom: 25px;
    text-transform: uppercase; }
    .slick--view--slide .slide__title a {
      color: #fff; }
  .slick--view--slide .slide__subtitle {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 40px; }

.slide__content {
  position: relative; }

.block-slideshow {
  margin-bottom: 30px; }

.view-pk-noibat .views-field-field-phukien-image,
.view-pk-noibat .field-name-field-product-image,
.view-sp-noibat .views-field-field-phukien-image,
.view-sp-noibat .field-name-field-product-image,
.view-san-pham .views-field-field-phukien-image,
.view-san-pham .field-name-field-product-image,
.view-list-san-pham .views-field-field-phukien-image,
.view-list-san-pham .field-name-field-product-image {
  overflow: hidden;
  margin-bottom: 10px; }
  .view-pk-noibat .views-field-field-phukien-image img,
  .view-pk-noibat .field-name-field-product-image img,
  .view-sp-noibat .views-field-field-phukien-image img,
  .view-sp-noibat .field-name-field-product-image img,
  .view-san-pham .views-field-field-phukien-image img,
  .view-san-pham .field-name-field-product-image img,
  .view-list-san-pham .views-field-field-phukien-image img,
  .view-list-san-pham .field-name-field-product-image img {
    transform: scale(1);
    transition: transform 0.3s ease; }
  .view-pk-noibat .views-field-field-phukien-image a,
  .view-pk-noibat .field-name-field-product-image a,
  .view-sp-noibat .views-field-field-phukien-image a,
  .view-sp-noibat .field-name-field-product-image a,
  .view-san-pham .views-field-field-phukien-image a,
  .view-san-pham .field-name-field-product-image a,
  .view-list-san-pham .views-field-field-phukien-image a,
  .view-list-san-pham .field-name-field-product-image a {
    display: block;
    line-height: 0; }
    .view-pk-noibat .views-field-field-phukien-image a:hover img,
    .view-pk-noibat .field-name-field-product-image a:hover img,
    .view-sp-noibat .views-field-field-phukien-image a:hover img,
    .view-sp-noibat .field-name-field-product-image a:hover img,
    .view-san-pham .views-field-field-phukien-image a:hover img,
    .view-san-pham .field-name-field-product-image a:hover img,
    .view-list-san-pham .views-field-field-phukien-image a:hover img,
    .view-list-san-pham .field-name-field-product-image a:hover img {
      transform: scale(1.1); }

.view-pk-noibat .product__title,
.view-sp-noibat .product__title,
.view-san-pham .product__title,
.view-list-san-pham .product__title {
  margin-bottom: 10px; }
  .view-pk-noibat .product__title a,
  .view-sp-noibat .product__title a,
  .view-san-pham .product__title a,
  .view-list-san-pham .product__title a {
    color: #fff; }
    .view-pk-noibat .product__title a:hover,
    .view-sp-noibat .product__title a:hover,
    .view-san-pham .product__title a:hover,
    .view-list-san-pham .product__title a:hover {
      color: #B33E3C; }

.view-pk-noibat .group__content .commerce-price-savings-formatter-price,
.view-sp-noibat .group__content .commerce-price-savings-formatter-price,
.view-san-pham .group__content .commerce-price-savings-formatter-price,
.view-list-san-pham .group__content .commerce-price-savings-formatter-price {
  color: #B33E3C;
  border: 0; }
  .view-pk-noibat .group__content .commerce-price-savings-formatter-price .price-amount,
  .view-sp-noibat .group__content .commerce-price-savings-formatter-price .price-amount,
  .view-san-pham .group__content .commerce-price-savings-formatter-price .price-amount,
  .view-list-san-pham .group__content .commerce-price-savings-formatter-price .price-amount {
    padding: 0;
    font-size: 16px;
    font-weight: 600; }

.view-pk-noibat .view-price-custom .field-content,
.view-sp-noibat .view-price-custom .field-content,
.view-san-pham .view-price-custom .field-content,
.view-list-san-pham .view-price-custom .field-content {
  font-weight: 600;
  color: #B33E3C; }

.view-pk-noibat,
.view-sp-noibat {
  padding-top: 20px; }
  .view-pk-noibat > .view-content,
  .view-sp-noibat > .view-content {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 3.125rem; }
    .view-pk-noibat > .view-content > .views-row,
    .view-sp-noibat > .view-content > .views-row {
      margin-right: 1.5625rem;
      margin-bottom: 25px;
      width: calc((100% - 1.5625rem) / 2); }
      @media screen and (max-width: 47.9375em) {
        .view-pk-noibat > .view-content > .views-row:nth-child(2n),
        .view-sp-noibat > .view-content > .views-row:nth-child(2n) {
          margin-right: 0; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .view-pk-noibat > .view-content > .views-row,
        .view-sp-noibat > .view-content > .views-row {
          margin-right: 1.25rem;
          width: calc((100% - 2.5rem) / 3); }
          .view-pk-noibat > .view-content > .views-row:nth-child(3n),
          .view-sp-noibat > .view-content > .views-row:nth-child(3n) {
            margin-right: 0; } }
      @media (min-width: 64em) {
        .view-pk-noibat > .view-content > .views-row,
        .view-sp-noibat > .view-content > .views-row {
          margin-right: 1.5625rem;
          width: calc((100% - 4.6875rem) / 4); }
          .view-pk-noibat > .view-content > .views-row:nth-child(4n),
          .view-sp-noibat > .view-content > .views-row:nth-child(4n) {
            margin-right: 0; } }
      .view-pk-noibat > .view-content > .views-row .views-field-name,
      .view-sp-noibat > .view-content > .views-row .views-field-name {
        font-size: 20px; }

.view-list-san-pham > .view-content {
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 3.125rem; }
  .view-list-san-pham > .view-content > .views-row {
    margin-bottom: 25px;
    margin-right: 1.5625rem;
    width: calc((100% - 3.125rem) / 3); }
    .view-list-san-pham > .view-content > .views-row:nth-child(3n) {
      margin-right: 0; }
    @media screen and (max-width: 35.4375em) {
      .view-list-san-pham > .view-content > .views-row {
        margin-right: 1.25rem;
        width: calc((100% - 1.25rem) / 2); }
        .view-list-san-pham > .view-content > .views-row:nth-child(2n) {
          margin-right: 0; }
        .view-list-san-pham > .view-content > .views-row:nth-child(3n) {
          margin-right: 20px; } }
    .view-list-san-pham > .view-content > .views-row .views-field-name {
      font-size: 20px; }
    .view-list-san-pham > .view-content > .views-row .views-field-php .field-content {
      font-weight: 600;
      color: #B33E3C; }

.view-list-san-pham .pager {
  margin-bottom: 50px; }

.field-type-commerce-price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
  margin-bottom: 15px; }
  .field-type-commerce-price .field-label {
    margin-right: 15px; }
  .field-type-commerce-price .commerce-price-savings-formatter-price {
    border: 0; }
    .field-type-commerce-price .commerce-price-savings-formatter-price .price-amount {
      padding: 0;
      color: #B33E3C; }

.form-item-attributes-field-product-color {
  display: flex;
  margin-bottom: 10px; }
  .form-item-attributes-field-product-color .form-radios {
    margin-left: 15px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    justify-items: center;
    align-items: center; }

.form-item-quantity {
  margin-bottom: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
  align-content: center; }
  .form-item-quantity label {
    margin-right: 15px; }
  .form-item-quantity .form-text {
    width: 40px;
    padding: 0 10px;
    height: 40px;
    text-align: center; }
  .form-item-quantity .commerce-quantity-plusminus-link a {
    color: #B33E3C; }
    .form-item-quantity .commerce-quantity-plusminus-link a:hover {
      background: none; }

.cloud-zoom-container .cloud-zoom-lens {
  border: 1px solid white; }

.cart_contents.form-wrapper {
  margin-bottom: 30px; }
  .cart_contents.form-wrapper .fieldset-wrapper {
    border: 1px solid #333;
    padding: 15px; }
  .cart_contents.form-wrapper table {
    width: 100%; }
    .cart_contents.form-wrapper table tr {
      background-color: transparent;
      border-bottom: 1px solid #333; }
      .cart_contents.form-wrapper table tr td {
        padding: 5px; }

.commerce-order-handler-area-order-total .component-total {
  color: #B33E3C;
  font-weight: bold; }

.slick--view--testimonial {
  position: relative;
  padding: 40px 0 0; }
  .slick--view--testimonial .slide__content {
    text-align: center; }
  .slick--view--testimonial .slick-arrow:before {
    color: #B33E3C; }

.block-quote-4 {
  margin-bottom: 3.125rem;
  padding: 2.5rem 0rem; }
  @media (min-width: 48em) {
    .block-quote-4 {
      padding: 3.125rem 0rem; } }
  @media (min-width: 48em) {
    .block-quote-4__inner {
      display: flex;
      align-items: center; } }
  @media screen and (max-width: 47.9375em) {
    .block-quote-4__image {
      margin-bottom: 0.9375rem; } }
  @media (min-width: 48em) {
    .block-quote-4__image {
      width: 9.375rem;
      padding: 2.5rem 1.875rem 2.5rem 0rem; } }
  @media (min-width: 80em) {
    .block-quote-4__image {
      width: 15.625rem;
      padding-right: 5.5rem; } }
  .block-quote-4__image img {
    margin: 0 auto; }
  .block-quote-4__content {
    border-left: 0.1875rem solid currentColor;
    padding: 1.1875rem 0.3125rem 0.9375rem 1.875rem;
    text-align: left; }
    @media (min-width: 48em) {
      .block-quote-4__content {
        width: calc(100% - 150px);
        padding: 1.1875rem 0.3125rem 0.9375rem 1.875rem; } }
    @media (min-width: 64em) {
      .block-quote-4__content {
        padding-left: 2.8125rem;
        padding-right: 2.8125rem; } }
    @media (min-width: 80em) {
      .block-quote-4__content {
        width: calc(100% - 250px);
        padding-left: 5.5rem;
        padding-right: 5.5rem; } }
  .block-quote-4__quote {
    font-size: 1rem;
    line-height: 1.33;
    margin-bottom: 0.75rem;
    text-transform: uppercase; }
    @media (min-width: 48em) {
      .block-quote-4__quote {
        font-size: 1.125rem; } }
    .block-quote-4__quote p {
      margin-bottom: 0; }
  .block-quote-4__name, .block-quote-4__position {
    font-size: 0.9375rem; }
  .block-quote-4__name {
    font-weight: 700; }
  @media (min-width: 48em) {
    .block-quote-4__item {
      padding-left: 2.8125rem; } }
  @media (min-width: 48em) {
    .block-quote-4__item--no-image .block-quote-4__content {
      float: none;
      width: auto; } }

.block-service {
  padding-top: 40px; }
  .block-service .content {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 3.125rem; }
  .block-service__item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    text-align: center;
    padding: 0 25px;
    justify-items: center;
    margin-bottom: 30px; }
    @media (min-width: 48em) {
      .block-service__item {
        margin-right: 1.5625rem;
        margin-bottom: 0;
        width: calc((100% - 3.125rem) / 3); }
        .block-service__item:nth-child(3n) {
          margin-right: 0; } }
    .block-service__item h4 {
      font-size: 20px;
      font-weight: bold; }
  .block-service__image {
    margin-bottom: 15px; }

.block-social-media-links {
  width: 60%;
  margin: 0; }
  .block-social-media-links .block-title {
    border: 0;
    padding: 0;
    font-size: 16px;
    font-weight: normal; }
    .block-social-media-links .block-title:before {
      border: 0; }

.block-support {
  margin: 0; }
  .block-support .container {
    margin: 0; }
  .block-support ul {
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    padding: 0; }
    @media (min-width: 48em) {
      .block-support ul {
        flex-flow: row nowrap; } }
    .block-support ul li {
      margin-right: 15px;
      color: #B33E3C;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media (min-width: 64em) {
        .block-support ul li {
          font-size: 14px; } }
      .block-support ul li:before {
        padding-right: 8px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 20px; }
      .block-support ul li a {
        color: #B33E3C; }
        .block-support ul li a:hover {
          text-decoration: underline; }
      .block-support ul li.icon-envelope-o {
        display: none; }
        @media (min-width: 64em) {
          .block-support ul li.icon-envelope-o {
            display: flex; } }

.view-new .views-row .node-new {
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px; }

.view-new .views-row:last-of-type .node-new {
  border-bottom: 0;
  padding-bottom: 0; }

.view-new .views-row .field-type-image {
  padding-right: 30px;
  width: 25%; }

.view-new .views-row .group-content {
  width: 75%; }

.view-category .view-content > .item-list > ul > li {
  margin-left: 0; }

.view-category .view-content a {
  color: #fff; }
  .view-category .view-content a:hover {
    color: #B33E3C; }

.view-category-product > .view-content {
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 3.125rem; }
  .view-category-product > .view-content > .views-row {
    margin-right: 0.0625rem;
    margin-bottom: 1px;
    width: calc((100% - 0.125rem) / 3);
    position: relative;
    height: 250px; }
    .view-category-product > .view-content > .views-row:nth-child(3n) {
      margin-right: 0; }
    .view-category-product > .view-content > .views-row .views-field-field-cta-image {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      overflow: hidden; }
      .view-category-product > .view-content > .views-row .views-field-field-cta-image img {
        transition: transform .3s ease;
        line-height: 0;
        position: absolute;
        transform: translateY(-50%) scale(1);
        top: 50%;
        height: 100%;
        width: auto;
        min-height: 100%;
        max-height: 100%; }
    .view-category-product > .view-content > .views-row .views-field-name {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      top: 0; }
      .view-category-product > .view-content > .views-row .views-field-name a {
        color: #fff;
        font-size: 24px;
        transform: scale(0); }
        .view-category-product > .view-content > .views-row .views-field-name a:before {
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          content: "";
          top: 0; }
    .view-category-product > .view-content > .views-row:hover .views-field-field-cta-image img {
      transform: translateY(-50%) scale(1.2); }

.cloud-zoom-gallery-thumbs {
  max-width: 420px;
  width: 100%;
  overflow: hidden; }
  .cloud-zoom-gallery-thumbs .slick-list {
    margin: 0 -2px; }
  .cloud-zoom-gallery-thumbs .slick-slide {
    padding: 0 2px; }
    .cloud-zoom-gallery-thumbs .slick-slide img {
      width: 80px;
      height: 80px; }

.cloud-zoom-container .cloud-zoom {
  margin-bottom: 5px; }

.page-node-44 #page-title {
  display: none; }

.page-node-44 .view-mode-full {
  margin-bottom: 50px; }

.page-node-44 .group-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  margin-bottom: 50px; }
  .page-node-44 .group-header .field-field-dich-vu-image {
    padding-right: 40px; }
  .page-node-44 .group-header .cloud-zoom-gallery-thumbs {
    display: none; }
  .page-node-44 .group-header .form-radios {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center; }
  .page-node-44 .group-header .form-type-radio {
    width: auto;
    margin-right: 10px; }
    .page-node-44 .group-header .form-type-radio .ajax-progress {
      display: none !important; }
    .page-node-44 .group-header .form-type-radio .field-name-title h2 {
      font-size: 16px;
      line-height: 18px; }
    .page-node-44 .group-header .form-type-radio .description-selected,
    .page-node-44 .group-header .form-type-radio .description {
      display: flex;
      flex-flow: column-reverse;
      justify-content: center;
      align-content: center;
      justify-items: center;
      text-align: center; }
  .page-node-44 .group-header .form-button-disabled {
    display: none; }

.view-list-pk .views-field-field-product-image {
  margin-bottom: 10px; }

.view-list-pk .views-field-title {
  margin-bottom: 10px; }
  .view-list-pk .views-field-title a {
    font-size: 1.125rem;
    color: #fff; }
    .view-list-pk .views-field-title a:hover {
      color: #B33E3C; }

.view-list-pk .views-field-commerce-price .commerce-price-savings-formatter-price {
  color: #B33E3C;
  border: 0; }
  .view-list-pk .views-field-commerce-price .commerce-price-savings-formatter-price .price-amount {
    padding: 0;
    font-size: 16px;
    font-weight: 600; }

.pager {
  font-size: 0.875rem;
  padding-left: 0.0625rem;
  margin: 1.875rem 0rem; }
  .pager li {
    border: 0.0625rem solid #111;
    float: left;
    margin: 0rem 0.625rem 0.3125rem 0rem;
    font-size: 1rem; }
    .pager li:last-of-type {
      margin-right: 0; }
  .pager .pager-ellipsis,
  .pager .pager-current,
  .pager a {
    display: block;
    padding: 0rem 0.625rem; }
  .pager .pager-ellipsis {
    border-color: transparent; }
  .pager a {
    color: #fff; }
  .pager-first a, .pager-previous a, .pager-next a, .pager-last a {
    position: relative;
    text-indent: -999em;
    overflow: hidden;
    width: 1.875rem; }
    .pager-first a:before, .pager-previous a:before, .pager-next a:before, .pager-last a:before {
      font-size: 1.375rem;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      text-indent: 0;
      top: 50%;
      transform: translateY(-50%); }
  .pager .pager-current {
    background: #B33E3C;
    border-color: #B33E3C;
    color: #fff;
    cursor: default;
    position: relative;
    z-index: 1;
    width: 47px; }

#search-block-form .container-inline {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center; }
  @media screen and (max-width: 47.9375em) {
    #search-block-form .container-inline {
      justify-content: flex-start; } }
  #search-block-form .container-inline .form-type-textfield {
    margin: 0;
    width: 200px; }
    @media (min-width: 80em) {
      #search-block-form .container-inline .form-type-textfield {
        width: 260px; } }

.block-search {
  margin-bottom: 0;
  position: relative; }
  .block-search .form-actions,
  .block-search .search-icon {
    font-size: 20px;
    cursor: pointer; }
  .block-search .search-icon--active {
    color: #B33E3C; }
  .block-search .form-autocomplete {
    background-image: none !important; }
  .block-search form .form-text {
    padding-right: 40px;
    border-top: 0;
    height: 30px;
    border-radius: 15px;
    background: transparent;
    border: 1px solid #808080; }
  .block-search form .form-actions {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    height: 30px;
    width: 40px;
    height: 100%;
    text-align: center;
    line-height: 30px; }
    .block-search form .form-actions:before {
      color: rgba(255, 255, 255, 0.5); }
  .block-search form .form-submit {
    opacity: 0;
    position: absolute;
    right: 0;
    height: 30px;
    width: 30px; }
  .block-search.search-box--show form {
    display: block; }
  .block-search.search-box--show .search-icon {
    font-size: 30px; }

@viewport {
  zoom: 1;
  width: extend-to-zoom; }

@-ms-viewport {
  width: extend-to-zoom;
  zoom: 1; }

html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  line-height: 30px; }

body {
  color: #fff;
  font-family: "OpenSans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background: url("../images/header-right.jpg") center repeat; }

.sidebar--first {
  width: 100%; }
  @media (min-width: 48em) {
    .sidebar--first {
      width: 25%;
      float: left; } }
  .sidebar--first + .main-content {
    width: 100%; }
    @media (min-width: 48em) {
      .sidebar--first + .main-content {
        width: 75%;
        float: left;
        padding-left: 50px; } }

.footer-wrapper {
  background: black;
  color: #fff; }
  .footer-wrapper .footer-columns {
    display: flex;
    flex-flow: row wrap; }
    .footer-wrapper .footer-columns .region {
      margin-right: 1.5625rem;
      margin-bottom: 0;
      width: calc((100% - 1.5625rem) / 2); }
      @media screen and (max-width: 47.9375em) {
        .footer-wrapper .footer-columns .region:nth-child(2n) {
          margin-right: 0; } }
      @media (min-width: 48em) {
        .footer-wrapper .footer-columns .region {
          margin-right: 1.5625rem;
          margin-bottom: 0;
          width: calc((100% - 4.6875rem) / 4); }
          .footer-wrapper .footer-columns .region:nth-child(4n) {
            margin-right: 0; } }

.ds-2col-stacked > .group-footer {
  padding-top: 50px; }

.breadcrumb {
  color: #B33E3C; }
  .breadcrumb a {
    position: relative; }
    .breadcrumb a:first-of-type {
      padding-left: 20px; }
      .breadcrumb a:first-of-type:before {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }

#block-system-main {
  margin: 0; }

.main-wrapper {
  padding-bottom: 40px; }

.ds-2col-stacked > .group-left {
  width: 100%;
  margin-bottom: 40px; }
  @media (min-width: 48em) {
    .ds-2col-stacked > .group-left {
      width: 40%;
      margin-bottom: 0px; } }

.ds-2col-stacked > .group-right {
  width: 100%; }
  @media (min-width: 48em) {
    .ds-2col-stacked > .group-right {
      width: 60%; } }

.view-commerce-cart-form table {
  width: 100%; }
  .view-commerce-cart-form table tr {
    background: transparent; }
    .view-commerce-cart-form table tr td {
      padding: 5px; }
    .view-commerce-cart-form table tr .form-text {
      width: 40px;
      padding: 0;
      text-align: center; }

.view-commerce-cart-form .line-item-total-raw {
  color: #B33E3C;
  font-weight: bold; }

.view-commerce-cart-form .form-submit {
  margin-right: 10px;
  margin-bottom: 10px; }

.checkout-buttons .form-submit {
  margin-right: 10px;
  margin-bottom: 10p; }

.page-node #page-title {
  display: none; }

.navigation ul {
  margin: 0;
  padding: 0;
  transition: all 0.35s ease; }
  @media screen and (max-width: 47.9375em) {
    .navigation ul {
      position: fixed;
      top: 54px;
      padding: 25px 20px;
      box-shadow: -4px 0 10px 0 rgba(3, 22, 49, 0.04);
      background: rgba(0, 0, 0, 0.8);
      border: solid 1px #808080;
      border-radius: 8px;
      width: 320px;
      height: 100%;
      z-index: 1;
      left: 100%;
      right: -100%;
      transition: all .5s ease-in-out; } }
  .navigation ul li {
    display: inline-block;
    padding: 0;
    margin-right: 35px; }
    @media screen and (max-width: 47.9375em) {
      .navigation ul li {
        display: block; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .navigation ul li {
        margin-right: 30px; } }
    .navigation ul li a {
      display: block;
      padding: 10px 0;
      color: #fff;
      font-size: 15px;
      position: relative;
      text-transform: uppercase; }
      @media (min-width: 64em) {
        .navigation ul li a {
          font-size: 17px; } }
      .navigation ul li a:before {
        height: 3px;
        position: absolute;
        content: '';
        transition: all 0.35s ease;
        background-color: #B33E3C;
        width: 0;
        bottom: 0;
        left: 0; }
      .navigation ul li a:hover, .navigation ul li a.active {
        color: #B33E3C;
        transition: all 0.35s ease; }
        .navigation ul li a:hover:before, .navigation ul li a.active:before {
          width: 100%; }
    .navigation ul li:first-of-type a {
      padding-left: 0; }

@media (min-width: 48em) {
  .main-menu {
    margin-top: 25px;
    width: 100%; } }

.main-menu__icon {
  cursor: pointer;
  font-size: 30px;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  transition: all 0.5s ease; }
  @media (min-width: 48em) {
    .main-menu__icon {
      display: none; } }

.main-menu.show #main-menu-links {
  right: 0;
  opacity: 1;
  left: calc(100% - 320px);
  z-index: 999999;
  transform: translateX(0); }

.main-menu.show .main-menu__icon {
  z-index: 1000000; }

.front .header {
  position: absolute; }

.logged-in .header {
  top: 53.8px; }
  @media only screen and (min-width: 888px) {
    .logged-in .header {
      top: 29px; } }

.header {
  color: #fff;
  transition: all 0.3s ease;
  left: 0;
  width: 100%;
  z-index: 999;
  top: 0;
  background: rgba(0, 0, 0, 0.6); }
  .header > .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    justify-items: stretch;
    position: relative;
    padding: 5px 0; }
    @media screen and (max-width: 47.9375em) {
      .header > .container {
        padding-bottom: 50px; } }
  .header__logo {
    width: 100%; }
    @media (min-width: 48em) {
      .header__logo {
        width: 20%; } }
    @media (min-width: 64em) {
      .header__logo {
        width: 15%;
        margin-right: 30px; } }
    .header__logo .logo {
      display: flex;
      justify-content: flex-start;
      align-content: center; }
      @media screen and (max-width: 47.9375em) {
        .header__logo .logo {
          align-content: center;
          margin: auto;
          width: 100%;
          text-align: center;
          display: inline-block; } }
      .header__logo .logo img {
        width: 100px;
        height: 100px; }
        @media screen and (max-width: 47.9375em) {
          .header__logo .logo img {
            margin: 0 auto; } }
        @media (min-width: 64em) {
          .header__logo .logo img {
            width: 125px;
            height: 125px; } }
  .header.fixed-header {
    top: 0;
    box-shadow: 0rem 0rem 0.5rem 0rem rgba(255, 255, 255, 0.1); }
  .header .region-header {
    position: absolute;
    top: 0;
    left: 16%;
    padding-top: 8px;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center; }
    @media screen and (max-width: 47.9375em) {
      .header .region-header {
        bottom: 0;
        left: 0;
        top: inherit;
        width: 100%;
        padding-bottom: 10px; } }
    .header .region-header .block-support {
      width: 100%; }
      @media (min-width: 48em) {
        .header .region-header .block-support {
          width: 60%; } }
    @media screen and (max-width: 47.9375em) {
      .header .region-header .block-search {
        display: none; } }
    @media (min-width: 48em) {
      .header .region-header .block-search {
        width: 40%;
        margin-right: 15px; } }
    .header .region-header .block-search + .block-commerce-popup-cart {
      width: 5%; }

.footer-wrapper ul {
  padding-left: 0; }
  .footer-wrapper ul a {
    color: #fff;
    font-size: 14px; }
    .footer-wrapper ul a:hover {
      color: #fff; }

.region-footer-top {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  justify-items: center;
  padding: 30px 0; }

.block-copyright {
  margin-bottom: 0;
  padding-bottom: 30px; }
  .block-copyright p {
    margin: 0; }

.block-map {
  width: 40%;
  margin: 0; }

.welcome-paragraph {
  padding: 2.5rem 0rem; }

.breadcrumb {
  padding: 40px 0 30px; }
  .breadcrumb a {
    color: #fff; }
    .breadcrumb a:hover {
      color: #B33E3C; }
