.slick--view--slide {
  position: relative;

  .slide {
    &__content {
      @extend .bg--dark--overlay;
      height: 600px;
      overflow: hidden;

      img {
        bottom: auto;
        height: 100%;
        left: 50%;
        max-width: inherit;
        position: absolute;
        right: auto;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        object-position: 100% 50%;
        object-fit: cover;

        @include susy-media(mobileLarge) {
          width: auto;
        }
      }
    }

    &__group-content {
      align-content:stretch;
      align-items: center;
      bottom: 0;
      display:flex;
      flex-flow: column wrap;
      justify-content:center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      color: $white;
    }

    &__title {
      margin-bottom: 25px;
      text-transform: uppercase;

      a {
        color: $white;
      }
    }

    &__subtitle {
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 40px;
    }

    &__link {
      a {
        @extend .btn;
      }
    }
  }

}

.slide__content {
  position: relative;
}

.block-slideshow {
  margin-bottom: 30px;
}
