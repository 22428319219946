// LAYOUT
// This is the main layout file.
// This file should be used to define the primary page layout that each of the page types will be extending.
// This includes global styles for html, body, etc.

@viewport{
  zoom: 1;
  width: extend-to-zoom;
}

@-ms-viewport{
  width: extend-to-zoom;
  zoom: 1;
}

//Enable font-smoothing
html {
  @include helvetica;
  font-size: $base-font-size; // Don't modify static unit.
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  line-height: $base-line-height;
}

body {
  // Disable in production mode;
  // @include show-breakpoints;
  color: $text-color;
  @include OpenSans;
  font-weight: 400;
  background: url("../images/header-right.jpg") center repeat;
}

.sidebar--first {
  width: 100%;

  @include susy-media(tablet) {
    width: 25%;
  float: left;
  }

  + .main-content {
    width: 100%;

    @include susy-media(tablet) {
      width: 75%;
      float: left;
      padding-left: 50px;
    }
  }
}

.footer-wrapper {
  background: black;
  color: $white;

  .footer-columns {
    display: flex;
    flex-flow: row wrap;

    .region {
      margin-right: rem(25px);
      margin-bottom: 0;
      width: calc((100% - #{rem(25px)}) / 2);

      @include susy-media(mobileOnly) {
        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @include susy-media(tablet) {
        margin-right: rem(25px);
        margin-bottom: 0;
        width: calc((100% - #{3 * rem(25px)}) / 4);

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}

.ds-2col-stacked > .group-footer {
  padding-top: 50px;
}

.breadcrumb {
  color: $primary-color;

  a {
    position: relative;

    &:first-of-type {
      padding-left: 20px;

      &:before {
        @extend %icons;
        @extend .icon-home;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

#block-system-main {
  margin: 0;
}

.main-wrapper {
  padding-bottom: 40px;
}

.ds-2col-stacked {
  > .group-left {
    width: 100%;
    margin-bottom: 40px;

    @include susy-media(tablet) {
      width: 40%;
      margin-bottom: 0px;
    }
  }

  > .group-right {
    width: 100%;

    @include susy-media(tablet) {
      width: 60%;
    }
  }
}

.view-commerce-cart-form {
  table {
    width: 100%;

    tr {
      background: transparent;

      td {
        padding: 5px;
      }

      .form-text {
        width: 40px;
        padding: 0;
        text-align: center;
      }
    }
  }

  .line-item-total-raw {
    color: $primary-color;
    font-weight: bold;
  }

  .form-submit {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.checkout-buttons {
  .form-submit {
    margin-right: 10px;
    margin-bottom: 10p;
  }
}

.page-node {
  #page-title {
    display: none;
  }
}
