.view-pk-noibat,
.view-sp-noibat,
.view-san-pham,
.view-list-san-pham {
  .views-field-field-phukien-image,
  .field-name-field-product-image {
    overflow: hidden;
    margin-bottom: 10px;

    img {
      transform: scale(1);
      transition: transform 0.3s ease;
    }

    a {
      display: block;
      line-height: 0;

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .product__title {
    margin-bottom: 10px;

    a {
      color: $text-color;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .group__content {
    .commerce-price-savings-formatter-price {
      color: $primary-color;
      border: 0;

      .price-amount {
        padding: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .view-price-custom  {
    .field-content {
      font-weight: 600;
      color: $primary-color;
    }
  }
}

.view-pk-noibat,
.view-sp-noibat {
  padding-top: 20px;

  > .view-content {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: rem(50px);

    > .views-row {
      margin-right: rem(25px);
      margin-bottom: 25px;
      width: calc((100% - #{rem(25px)}) / 2);

      @include susy-media(mobileOnly) {
        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @include susy-media(tabletOnly) {
        margin-right: rem(20px);
        width: calc((100% - #{2 * rem(20px)}) / 3);

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @include susy-media(desktop) {
        margin-right: rem(25px);
        width: calc((100% - #{3 * rem(25px)}) / 4);

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      .views-field-name {
        font-size: 20px;
      }
    }
  }
}

.view-list-san-pham {
  > .view-content {
      align-items: stretch;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      margin-bottom: rem(50px);

    > .views-row {
      margin-bottom: 25px;
      margin-right: rem(25px);
      width: calc((100% - #{2 * rem(25px)}) / 3);

      &:nth-child(3n) {
        margin-right: 0;
      }

      @include susy-media(mobileOnly1) {
        margin-right: rem(20px);
        width: calc((100% - #{rem(20px)}) / 2);

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:nth-child(3n) {
          margin-right: 20px;
        }
      }

      .views-field-name {
        font-size: 20px;
      }

      .views-field-php {
        .field-content {
          font-weight: 600;
          color: $primary-color;
        }
      }
    }
  }

  .pager {
    margin-bottom: 50px;
  }
}

.field-type-commerce-price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
  margin-bottom: 15px;

  .field-label {
    margin-right: 15px;
  }

  .commerce-price-savings-formatter-price {
    border: 0;

    .price-amount {
      padding: 0;
      color: $primary-color;
    }
  }
}

.form-item-attributes-field-product-color {
  display: flex;
  margin-bottom: 10px;

  .form-radios {
    margin-left: 15px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    justify-items: center;
    align-items: center;
  }
}

.form-item-quantity {
  margin-bottom: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
  align-content: center;

  label {
    margin-right: 15px;
  }

  .form-text {
    width: 40px;
    padding: 0 10px;
    height: 40px;
    text-align: center;
  }

  .commerce-quantity-plusminus-link {
    a {
      color: $primary-color;

      &:hover {
        background: none;
      }
    }
  }
}

.cloud-zoom-container {
  .cloud-zoom-lens {
    border: 1px solid white;
  }
}

.cart_contents.form-wrapper {
  margin-bottom: 30px;

  .fieldset-wrapper {
    border: 1px solid #333;
    padding: 15px;
  }

  table {
    width: 100%;

    tr {
      background-color: transparent;
      border-bottom: 1px solid #333;

      td {
        padding: 5px;
      }
    }
  }
}

.commerce-order-handler-area-order-total {
  .component-total {
    color: $primary-color;
    font-weight: bold;
  }
}
