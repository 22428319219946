button,
input[type='submit'] {
  @extend .btn;
}

.grippie {
  display: none;
}

%form-text {
  @include helvetica;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: rem(1px) solid $gray;
  box-shadow: inherit;
  box-sizing: border-box;
  color: $white;
  font-size: rem(16px);
  font-weight: 400;
  height: rem(40px);
  padding: rem(7px 20px);
  transition: all .3s ease-in-out;
  width: 100%;
  border-radius: 5px;

  &::-ms-clear {
    display: none;
  }

  &::-webkit-input-placeholder {
    color: $gray;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $gray;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $gray;
    opacity: 1;
  }

  &:-moz-placeholder {
    color: $gray;
    opacity: 1;
  }

  &:focus {
    outline: 0 none;
  }

  &.error {
    border-color: red;
    color: red;

    &::-webkit-input-placeholder {
      color: red;
    }

    &::-moz-placeholder {
      color: red;
    }

    &:-ms-input-placeholder {
      color: red;
    }

    &:-moz-placeholder {
      color: red;
    }

    &:focus {
      outline: 0 none;
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

input[type='tel'],
.form-url,
.form-search,
.form-email,
.form-text {
  @extend %form-text;
}

.form-textarea {
  @extend %form-text;
  height: auto;
  min-height: rem(200px);
  padding: rem(15px 25px);
}

.form-item,
.form-actions {
  margin:rem(0 0 15px);

  &.form-type-checkbox,
  &.form-type-radio {
    margin-bottom: rem(8px);
  }

  label {
    color: $text-color;
    display: inline-block;
    font-weight: 700;
    margin-bottom: rem(5px);
  }
}

// .form-type-textfield {
//   display: flex;
//   flex-flow: column-reverse;
// }

.form-type-checkbox,
.form-type-radio {
  label {
    color: $text-color;
  }
}

.fieldset-legend {
  display: inline-block;
  font-weight: 700;
  margin-bottom: rem(8px);
}

input:focus + label {
  color: $primary-color;
  transition: all 0.7s ease;
}

.select-arrow-icon {
  display: none;
}

.touchevents {
  .form-type-select {
    .select-arrow-icon {
      color: $text-color;
      font-size: rem(16px);
      height: rem(15px);
      line-height: rem(15px);
      margin-top: rem(20px);
      position: absolute;
      right: rem(15px);
      text-align: center;
      text-align: left;
      text-indent: -999em;
      width: rem(15px);
      z-index: 3;
      display: block;

      &:before {
        @extend %icons;
        @extend .icon-chevron-thin-down:before;
        display: block;
        float: left;
        height: 100%;
        text-align: center;
        text-indent: 0;
        transform: rotate(0);
        width: 100%;
      }
    }

    @include susy-media(mobileOnly) {
      position: relative;

      select {
        appearance: none;
        border: rem(1px) solid $gray;
        background: $white;
        font-size: rem(14px);
        height: rem(56px);
        padding: rem(6px 40px 6px 15px);
        width: 100%;
        box-sizing: border-box;
        border-radius: 0;

        &.focused,
        &:focus {
          border-color: $primary-color;
          outline: none;
        }
      }

      .select-arrow-icon {
        display: block;
      }
    }

    select {
      position: relative;
      z-index: 2;
    }
  }
}

.ds-2col-stacked {
  .group-right {
    ul {
      li {
        list-style: inside;
        padding-left: 15px;
      }
    }
  }

  .group-footer {
    padding-top: 30px;
    margin-bottom: 50px;

    .group-content {
      border: 1px solid rgba(255,255,255,0.2);
      padding: 10px;
    }

    .fieldset-wrapper {
      ul {
        li {
          list-style: inside;
        }
      }
    }

    div.horizontal-tabs {
      border: none;

      .horizontal-tabs-list {
        background: transparent;
        border-right: none;
        margin-bottom: 15px;

        .horizontal-tab-button {
          background: transparent;
          border: none;
          padding: 0;
          margin-right: 10px;

          a {
            padding: 5px 15px;

            &:hover {
              background-color: transparent;
            }
          }

          a strong{
            color: $white;

            &:hover {
              color: $white;
              background-color: transparent;
            }
          }

          &:hover,
          &.selected {
            background: $primary-color;

            a {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.comment-form {
  .form-item, .form-actions {
    display: flex;
    justify-content: flex-start;

    label {
      max-width: 120px;
      width: 100%;
      margin-right: 10px;
    }
  }

  .form-textarea-wrapper,
  .form-textarea,
  .form-text {
    max-width: 300px;

    @include susy-media(tablet) {
      max-width: 450px;
    }
  }
}
