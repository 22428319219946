// Navigation.
$menu-hover-lines-transition: all 0.35s ease;
$menu-hover-lines-border-width: 3px;
.navigation {
  ul {
    // float: right;
    margin: 0;
    padding: 0;
    transition: $menu-hover-lines-transition;

    @include susy-media(mobileOnly) {
      position: fixed;
      top: 54px;
      padding: 25px 20px;
      box-shadow: -4px 0 10px 0 rgba(3, 22, 49, 0.04);
      background: rgba(0,0,0,0.8);
      border: solid 1px $gray;
      border-radius: 8px;
      width: 320px;
      height: 100%;
      z-index: 1;
      left: 100%;
      right: -100%;
      transition: all .5s ease-in-out;
    }

    li {
      display: inline-block;
      padding: 0;
      margin-right: 35px;

      @include susy-media(mobileOnly) {
        display: block;
      }

      @include susy-media(tabletOnly) {
        margin-right: 30px;
      }

      a {
        display: block;
        padding: 10px 0;
        color: $white;
        font-size: 15px;
        position: relative;
        text-transform: uppercase;

        @include susy-media(desktop) {
          font-size: 17px;
        }

        &:before {
          height: $menu-hover-lines-border-width;
          position: absolute;
          content: '';
          transition: $menu-hover-lines-transition;
          background-color: $primary-color;
          width: 0;
          bottom: 0;
          left: 0;
        }

        &:hover,
        &.active {
          color: $primary-color;
          transition: $menu-hover-lines-transition;

          &:before {
            width: 100%;
          }
        }
      }

      &:first-of-type {
        a {
          padding-left: 0;
        }
      }
    }
  }
}

.main-menu {
  @include susy-media(tablet) {
    margin-top: 25px;
    width: 100%;
  }

  &__icon {
    cursor: pointer;
    @extend %icons;
    @extend .icon-menu;
    font-size: 30px;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    transition: all 0.5s ease;

    @include susy-media(tablet) {
      display: none;
    }
  }

  &.show {
    #main-menu-links {
      right: 0;
      opacity: 1;
      left: calc(100% - 320px);
      z-index: 999999;
      transform: translateX(0);
    }

    .main-menu__icon {
      @extend .icon-close3;
      z-index: 1000000;
    }
  }
}

.main-menu + .region-header {
  // width: 5%;
  // text-align: right;
}
