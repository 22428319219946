.cloud-zoom-gallery-thumbs {
  max-width: 420px;
  width: 100%;
  overflow: hidden;

  .slick-list {
    margin: 0 -2px;
  }

  .slick-slide {
    padding: 0 2px;

    img {
      width: 80px;
      height: 80px;
    }
  }
}

.cloud-zoom-container {
  .cloud-zoom {
    margin-bottom: 5px;
  }
}

.page-node-44 {
  #page-title {
    display: none;
  }

  .view-mode-full  {
    margin-bottom: 50px;
  }

  .group-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin-bottom: 50px;

    .field-field-dich-vu-image {
      padding-right: 40px;
    }

    .cloud-zoom-gallery-thumbs {
      display: none;
    }

    .form-radios {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-content: center;
    }

    .form-type-radio {
      width: auto;
      margin-right: 10px;

      .ajax-progress {
        display: none !important;
      }

      .field-name-title {
        h2 {
          font-size: 16px;
          line-height: 18px;
        }
      }

      .description-selected,
      .description {
        display: flex;
        flex-flow: column-reverse;
        justify-content: center;
        align-content: center;
        justify-items: center;
        text-align: center;
      }
    }

    .form-button-disabled {
      display: none;
    }
  }
}

.view-list-pk {
  .views-field-field-product-image {
    margin-bottom: 10px;
  }

  .views-field-title {
    margin-bottom: 10px;

    a {
      font-size: 1.125rem;
      color: $text-color;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .views-field-commerce-price {
    .commerce-price-savings-formatter-price {
      color: $primary-color;
      border: 0;

      .price-amount {
        padding: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
